

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Translation from '@/components/Translation.vue';
import { SearchType } from '@/common/enums';
import { TranslationModel } from '@/common/models';

@Component({
  components: {
    Translation,
  },
})
export default class Search extends Vue {
  @Prop() type: SearchType;
  @Prop() query: string;

  get searchResults() {
    return this.$store.state.search.results;
  }

  @Watch('type')
  @Watch('query')
  queryWatch() {
    this.search();
  }

  async mounted() {
    if (this.type != null && this.query != null) {
      await this.search();
    } else {
      this.$router.push({ name: 'home', query: { page: this.$store.state.translations.currentPage.toString() } });
    }
  }

  onTranslationClick(translation: TranslationModel, { event }) {
    this.$router.push({
      name: 'home',
      query: {
        page: Math.ceil((translation.index + 1) / this.$store.state.translations.pageLength).toString(),
        wordlist: 'true',
      },
      hash: `#${translation._id}`,
    });
    event.stopPropagation();
  }

  private search() {
    this.$store.dispatch('search', { query: this.query, type: this.type });
  }
}
